export default {
  setUser(state, newUser) {
    state.user = newUser
  },
  setAppFeatures(state, features) {
    state.appFeatures = features
  },
  setDownloadAppLink(state, link) {
    state.downloadAppLink = link
  },
}
