<template>
  <div class="features-list">
    <div v-for="feature in featuresList" :key="feature.text || feature" class="feature-item">
      <div class="item-row">
        <span class="icon-container">
          <inline-svg
            :src="require(`@/assets/icons/${feature.icon || 'check.svg'}`)"
            title="item icon"
            width="10"
          ></inline-svg>
        </span>
        {{ $t(feature.text || feature) }}
      </div>
      <div class="item-subfeatures" v-if="feature.subFeatures">
        <div
          v-for="subFeature in feature.subFeatures"
          :key="subFeature"
          class="subfeature"
        >
          <span>—</span> {{ $t(subFeature) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg'

export default {
  props: {
    featuresList: {
      type: Array,
      required: true,
    },
  },
  components: {
    InlineSvg,
  },
}
</script>

<style lang="scss" scoped>
.features-list {
  width: 100%;
  padding: 0.5rem 0 0.5rem 1rem;

  .feature-item {
    line-height: 1.5;

    .item-row {
      display: flex;

      .icon-container {
        margin-right: 11px;
      }
    }

    .item-subfeatures {
      padding-left: 2rem;

      .subfeature {
        display: flex;
        span {
          margin-right: 5px;
        }
      }
    }
  }
}
</style>
