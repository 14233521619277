import * as authService from '../db/auth'
import * as appFeaturesService from '../db/app-features'
import { APPLE_CLIENT_REGEX, APPSTORE_LINK, GOOGLEPLAY_LINK } from '../shared/constants'

export default {
  async loginWithGoogle() {
    await authService.signInWithGoogle()
  },
  async loginWithFacebook() {
    await authService.signInWithFacebook()
  },
  async reviewRedirectResult({ dispatch }) {
    try {
      const loggedUser = await authService.getRedirectResult()
      if (loggedUser) {
        console.log('Correct login', loggedUser.uid)
      }
    } catch (e) {
      console.error(e)
      // process login errors here
    }
  },
  async loadUser({ commit }, firebaseUser) {
    let user = null
    if (firebaseUser) {
      const newUser = {
        uid: firebaseUser.uid,
        email: firebaseUser.email,
        displayName: firebaseUser.displayName,
        photoUrl: firebaseUser.photoURL,
        provider: firebaseUser.providerData[0].providerId,
        providerId: firebaseUser.providerData[0].uid,
      }
      commit('setUser', newUser)
      user = await authService.saveUser(newUser)
    }
    commit('setUser', user)
  },
  async logout({ commit }) {
    await authService.signOut()
    commit('setUser', null)
  },
  async loadFeatures({ commit, state }) {
    if (state.appFeatures) {
      return
    }
    const appFeaturesSnap = await appFeaturesService.getAppFeatures()
    const appFeatures = appFeaturesSnap.data()
    commit('setAppFeatures', appFeatures)
  },
  setAppLink({ commit }) {
    const userAgent = navigator.userAgent
    const isIOS = APPLE_CLIENT_REGEX.test(userAgent)
    commit('setDownloadAppLink', isIOS ? APPSTORE_LINK : GOOGLEPLAY_LINK)
  },
}
