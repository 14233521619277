<template>
  <div class="home">
    <HomeHeader :scrollTo="scrollTo"></HomeHeader>
    <main>
      <HomeBannerSection :scrollTo="scrollTo"></HomeBannerSection>
      <PromotionalVideoSection id="promotional-video"/>
      <HomeFeaturesSection id="features"></HomeFeaturesSection>
      <PaymentsSection id="payments"></PaymentsSection>
      <HomeTournamentsSection id="tournaments"></HomeTournamentsSection>
      <HomeSubscribeSection id="contact" />
      <FooterSection />
    </main>
  </div>
</template>

<script>
// @ is an alias to /src
import HomeHeader from '@/components/HomeHeader/HomeHeader'
import HomeBannerSection from '@/components/HomeBannerSection/HomeBannerSection'
import HomeFeaturesSection from '@/components/HomeFeaturesSection/HomeFeaturesSection'
import FooterSection from '@/components/FooterSection/FooterSection'
import PaymentsSection from '@/components/PaymentsSection/PaymentsSection'
import HomeTournamentsSection from '@/components/HomeTournamentsSection/HomeTournamentsSection'
import HomeSubscribeSection from '@/components/HomeSubscribeSection/HomeSubscribeSection'
import PromotionalVideoSection from '@/components/PromotionalVideoSection/PromotionalVideoSection'

export default {
  name: 'Home',
  methods: {
    scrollTo(selector, offset = 100) {
      if (!selector) {
        return
      }
      const element = document.querySelector(selector)
      if (element) {
        window.scroll(0, element.offsetTop - offset)
      }
    },
  },
  created: function() {
    this.$store.dispatch('setAppLink')
  },
  components: {
    FooterSection,
    HomeBannerSection,
    HomeFeaturesSection,
    HomeHeader,
    HomeSubscribeSection,
    HomeTournamentsSection,
    PaymentsSection,
    PromotionalVideoSection,
  },
  metaInfo() {
    return {
      title: 'Home',
      meta: [{ name: 'description', content: this.$t('appDescription') }],
    }
  },
}
</script>

<style lang="scss" scoped>
.home {
  header {
    position: sticky;
    top: 0;
    z-index: 999;
  }
}
</style>
