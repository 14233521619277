export const FEATURES = {
  uleaguePlayer: [
    'uleaguePlayer.features.searchParticipate',
    'uleaguePlayer.features.knowDetail',
    'uleaguePlayer.features.matchesList',
    'uleaguePlayer.features.chatOpponents',
    'uleaguePlayer.features.registerResults',
    'uleaguePlayer.features.statistics',
    'uleaguePlayer.features.shareAndRate',
    {
      icon: 'secure.svg',
      text: 'uleaguePlayer.features.secureMoney',
    },
  ],
  // Default number
  uleaguePlayerNum: 350,
  uleagueAdmin: [
    'uleagueAdmin.features.createAndEdit',
    'uleagueAdmin.features.chatWithPlayers',
    'uleagueAdmin.features.warrantedMoney',
    {
      text: 'uleagueAdmin.features.customize',
      subFeatures: [
        'uleagueAdmin.features.selectTournament',
        'uleagueAdmin.features.rules',
        'uleagueAdmin.features.prizes',
        'uleagueAdmin.features.chargeFee',
      ],
    },
  ],
  // Default number
  uleagueAdminNum: 24,
}

export const SOCIAL_MEDIA = {
  instagram: 'https://instagram.com/uleagueapp',
  facebook: 'https://www.facebook.com/Uleague-102798395581339',
}

export const CONSOLES = {
  ps4: 'PS4',
  ps5: 'PS5',
  xbox_one: 'XBOX ONE',
  xbox_series_x_s: 'XBOX SERIES X|S',
}

export const GAMES = {
  fifa_19: 'FIFA 19',
  fifa_20: 'FIFA 20',
  fifa_21: 'FIFA 21',
  pes_2019: 'PES 2019',
  pes_2020: 'PES 2020',
  pes_2021: 'PES 2021',
}

export const APPLE_CLIENT_REGEX = /iPod|iPad|iPhone|Macintosh/gi
// TODO Set the real links
export const APPSTORE_LINK = 'https://apps.apple.com/app/uleague/id1571596476'
export const GOOGLEPLAY_LINK = 'https://play.google.com/store/apps/details?id=com.uleague'
// export const APPSTORE_LINK = 'https://chat.whatsapp.com/IhJzhU6tDEO924DCN30rZz'
// export const GOOGLEPLAY_LINK = 'https://chat.whatsapp.com/IhJzhU6tDEO924DCN30rZz'
