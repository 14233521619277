import { tournamentsCollection } from './firebase'

const DEFAULT_LIMIT = 10

export function getHomeTournaments(limit = DEFAULT_LIMIT) {
  return tournamentsCollection
    .where('isOfficialTournament', '==', true)
    .orderBy('createdAt', 'desc')
    .limit(limit)
    .get()
}
