<template>
  <div class="lang-selector">
    <button v-for="(value, lang) in supportedLangs" :key="lang" :class="lang === $i18n.locale ? 'selected' : ''" @click="changeLang(lang)">
      {{ lang.toUpperCase() }}
    </button>
  </div>
</template>

<script>
import { supportedLangs, changeLanguage } from '@/i18n'

export default {
  data() {
    return {
      supportedLangs,
    }
  },
  methods: {
    changeLang(newLang) {
      changeLanguage(newLang)
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/css/base.scss";

.lang-selector {
  button {
    color: $primaryColor;
    border: none;
    background-color: transparent;
    cursor: pointer;

    &:not(:first-of-type) {
      border-left: 1px solid $primaryColor;
    }
  }

  .selected {
    font-weight: bold;
  }
}
</style>
