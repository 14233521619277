<template>
  <Layout class="not-found">
    <PageWrapper>
      <div class="not-found-container">
        <h1>404</h1>
        <p>{{ $t("error.pageNotFound") }}</p>
      </div>
    </PageWrapper>
  </Layout>
</template>

<script>
import Layout from '@/components/Layout/Layout'
import PageWrapper from '@/components/PageWrapper/PageWrapper'

export default {
  components: {
    Layout,
    PageWrapper,
  },
}
</script>

<style lang="scss" scoped>
@import "@/css/base.scss";

.not-found {
  .not-found-container {
    text-align: center;
    color: $primaryColor;

    h1 {
      font-size: $fifty;
    }
  }
}
</style>
