<template>
  <section :id="id" class="payments-section">
    <PageWrapper>
      <div class="payment-grid">
        <div class="secure-text">{{ $t("securePaymentsWith") }}</div>
        <!-- TODO: Payment methods will be added here -->
        <inline-svg :src="require('@/assets/icons/payments/paypal.svg')" height="50" fill="white"></inline-svg>
        <inline-svg :src="require('@/assets/icons/payments/appstore.svg')" height="50" fill="white"></inline-svg>
        <inline-svg :src="require('@/assets/icons/payments/googleplay.svg')" height="50" fill="white"></inline-svg>
      </div>
    </PageWrapper>
  </section>
</template>

<script>
import PageWrapper from '@/components/PageWrapper/PageWrapper'
import InlineSvg from 'vue-inline-svg'

export default {
  props: {
    id: String,
  },
  components: {
    InlineSvg,
    PageWrapper,
  },
}
</script>

<style lang="scss" scoped>
@import "@/css/base.scss";

.payments-section {
  background-color: $primaryColor;
  padding: 2rem 0;

  .payment-grid {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 3rem;

    .secure-text {
      text-transform: uppercase;
      color: $whiteColor;
      font-size: $fourteen;
      letter-spacing: 1px;
    }
  }
}
</style>
