import Firebase from 'firebase/app'
import { auth, db, usersCollection } from './firebase'

export function signInWithGoogle() {
  const provider = new Firebase.auth.GoogleAuthProvider()
  return auth.signInWithRedirect(provider)
}

export function signInWithFacebook() {
  const provider = new Firebase.auth.FacebookAuthProvider()
  return auth.signInWithRedirect(provider)
}

export async function getRedirectResult() {
  const redirectResult = await auth.getRedirectResult()
  return redirectResult.user
}

export function signOut() {
  return auth.signOut()
}

export async function saveUser(newUser) {
  if (!newUser) {
    return null
  }

  const userRef = usersCollection.doc(newUser.uid)
  let userSnap = await userRef.get()

  if (userSnap.exists) {
    return userSnap.data()
  }

  // User does not exist, then create it in database
  await db.runTransaction(async tx => {
    userSnap = await tx.get(userRef)

    if (userSnap.exists) {
      throw new Error('User should not exist')
    }

    return tx.set(userRef, newUser)
  })

  userSnap = await userRef.get()
  return userSnap.data()
}
