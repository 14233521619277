<template>
  <section class="main-banner">
    <div class="dashed-circle-group">
      <ImageDecoration
        type="dashed-semicircle"
        className="semicircle"
        :width="172"
      >
      </ImageDecoration>
      <ImageDecoration type="circle" className="circle" :width="21">
      </ImageDecoration>
    </div>
    <ImageDecoration type="circle" className="circle-1" :width="21">
    </ImageDecoration>
    <ImageDecoration type="circle" className="circle-2" :width="21">
    </ImageDecoration>
    <ImageDecoration type="circle" className="circle-3" :width="21">
    </ImageDecoration>
    <ImageDecoration type="triangle" className="triangle-1" :width="21">
    </ImageDecoration>
    <ImageDecoration type="triangle" className="triangle-2" :width="21">
    </ImageDecoration>
    <ImageDecoration type="cube" className="cube-1" :width="60">
    </ImageDecoration>
    <ImageDecoration
      type="dashed-semicircle"
      className="semicircle-2"
      :width="172"
    >
    </ImageDecoration>
    <PageWrapper>
      <div class="main-banner-grid">
        <div class="banner-text">
          <p><IndentLine></IndentLine>{{ $t("homePage.subtitle") }}</p>
          <h1>{{ $t("homePage.title") }}</h1>
        </div>
        <div class="banner-desc">
          <p>{{ $t("homePage.description") }}</p>
        </div>
        <div class="banner-illustration">
          <img src="@/assets/imgs/illustration.svg" alt="ULeague playing" />
        </div>
        <div class="banner-buttons">
          <a :href="downloadAppLink" target="_blank" class="btn-solid" @click="trackStartPlayingEvent">
            {{ $t("startPlaying") }}
          </a>
          <router-link to="#promotional-video" @click.native="scroll" class="btn-solid btn-outline">
            {{ $t("howDoesItWork") }}
            <inline-svg
              :src="require('@/assets/icons/play-button.svg')"
              width="22"
            ></inline-svg>
          </router-link>
          <div class="stores-section" v-if="!downloadAppLink.includes('whatsapp')">
            <img src="@/assets/imgs/2_logos.png" alt="Stores Logos" />
          </div>
        </div>
        <div class="banner-scroll">
          <inline-svg
            :src="require('@/assets/icons/scroll.svg')"
            title="Scroll"
            width="22"
          ></inline-svg>
          <inline-svg
            :src="require('@/assets/icons/scroll-mobile.svg')"
            title="Scroll mobile"
            width="14"
          ></inline-svg>
        </div>
      </div>
      <div class="advantages">
        <div class="advantage-item" v-for="ad in advantages" :key="ad.key">
          <div class="icon-rounded-wrapper">
            <inline-svg
              :src="require(`@/assets/icons/${ad.icon.name}`)"
              :title="ad.icon.alt"
              :width="ad.icon.width"
            ></inline-svg>
          </div>
          <h3>{{ $t(ad.titleI18n) }}</h3>
          <p>{{ $t(ad.subtitleI18n) }}</p>
        </div>
      </div>
    </PageWrapper>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import InlineSvg from 'vue-inline-svg'
import PageWrapper from '@/components/PageWrapper/PageWrapper'
import ImageDecoration from '@/components/decorations/ImageDecoration'
import IndentLine from '@/components/decorations/IndentLine'

export default {
  props: {
    scrollTo: Function,
  },
  data() {
    return {
      advantages: [
        {
          icon: {
            name: 'safe.svg',
            width: 56,
            alt: 'ULeague Save',
          },
          key: 1,
          subtitleI18n: 'homePage.safeDesc',
          titleI18n: 'homePage.safe',
        },
        {
          icon: {
            name: 'game.svg',
            width: 56,
            alt: 'ULeague Game',
          },
          key: 2,
          subtitleI18n: 'homePage.gameDesc',
          titleI18n: 'homePage.game',
        },
        {
          icon: {
            name: 'retire.svg',
            width: 37,
            alt: 'Retire with ULeague',
          },
          key: 3,
          subtitleI18n: 'homePage.retireDesc',
          titleI18n: 'homePage.retire',
        },
        {
          icon: {
            name: 'community.svg',
            width: 54,
            alt: 'ULeague Comunity',
          },
          key: 4,
          subtitleI18n: 'homePage.easyManageDesc',
          titleI18n: 'homePage.easyManage',
        },
      ],
    }
  },
  computed: {
    ...mapState(['downloadAppLink']),
  },
  components: {
    ImageDecoration,
    IndentLine,
    InlineSvg,
    PageWrapper,
  },
  methods: {
    scroll() {
      this.scrollTo(this.$route.hash)
      // eslint-disable-next-line no-undef
      fbq('trackCustom', 'HowDoesItWork', {})
    },
    trackStartPlayingEvent() {
      // eslint-disable-next-line no-undef
      fbq('trackCustom', 'StartPlaying', {})
      // If you want the link to work as usual after tracking, return true.
      // Otherwise, you can return false to prevent the default behavior of the link.
      return true
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/css/base.scss";

.main-banner {
  position: relative;
  padding-bottom: 3rem;
  overflow: hidden;

  .main-banner-grid {
    display: grid;
    grid-template-areas:
      "text illustration"
      "text illustration"
      "desc illustration"
      "buttons illustration"
      "scroll scroll";
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 2rem 1fr 4rem 1fr 50px;
    gap: 5px 20px;
    height: 470px;

    .banner-text {
      grid-area: text;
      text-transform: uppercase;
      text-align: left;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      h1 {
        font-size: min(calc(100vw / 30), #{$fifty});
        font-weight: 700;
        margin: 1rem 0 0;
        color: $primaryColor;
        letter-spacing: 1.15px;
        line-height: 1;
        width: 85%;
      }

      p {
        font-size: $twelve;
        color: $grey1Color;
        margin: 0;
      }
    }

    .banner-desc {
      grid-area: desc;
      color: $grey1Color;

      p {
        font-size: min(calc(100vw / 60), #{$twentytwo});
        margin-top: 0.5rem;
      }
    }

    .banner-illustration {
      grid-area: illustration;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      img {
        max-width: 700px;
      }
    }

    .banner-buttons {
      grid-area: buttons;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .btn-solid {
        min-width: 220px;
        margin-right: 10px;
      }

      .stores-section {
        padding-left: 0.5rem;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          max-width: 92px;
        }
      }
    }

    .banner-scroll {
      grid-area: scroll;
      display: flex;
      justify-content: center;
      align-items: center;

      svg:nth-child(2) {
        display: none;
      }
    }
  }

  .btn-solid {
    color: $whiteColor;
    background-color: $primaryColor;
  }
  .btn-outline {
    color: $primaryColor;
    background-color: $whiteColor;
    border: 1px solid $primaryColor;
    white-space: nowrap;

    svg {
      margin-left: 14px;
    }
  }

  .advantages {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 2rem;

    .advantage-item {
      width: 22%;
      text-align: center;
      display: grid;

      .icon-rounded-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        border-radius: 50%;
        border: 2px solid $secondaryColor;
        width: 105px;
        max-width: 105px;
        height: 105px;
        max-height: 105px;
        box-shadow: 0 2px 10px $darkShadowColor;
      }

      h3 {
        text-transform: uppercase;
        font-size: $eighteen;
        color: $primaryColor;
        letter-spacing: 1px;
        margin-bottom: 0;
      }

      p {
        color: $grey2Color;
        font-size: $sixteen;
      }
    }
  }

  .circle-1 {
    top: 4%;
    right: 25%;
  }

  .circle-2 {
    top: 70%;
    left: 0;
  }

  .circle-3 {
    bottom: 1rem;
    right: 30%;
  }

  .triangle-1 {
    top: 7%;
    left: 35%;
    transform: rotate(-70deg);
  }

  .triangle-2 {
    left: 30%;
    top: 52%;
  }

  .cube-1 {
    transform: rotateY(180deg);
    right: -1rem;
    bottom: 3rem;
  }

  .semicircle-2 {
    bottom: -5px;
    left: 0;
  }

  .dashed-circle-group {
    position: absolute;
    top: 10%;

    .semicircle {
      transform: rotate(130deg);
      left: -6.5rem;
      top: 4.1rem;
    }

    .circle {
      left: 1.5rem;
    }
  }

  @media (max-width: $largeTabletWidth) {
    .main-banner-grid {
      .banner-buttons {
        .stores-section {
          display: none;
        }
      }
    }
  }

  @media (max-width: $tabletWidth) {
    .main-banner-grid {
      grid-template-areas:
        "text"
        "text"
        "illustration"
        "illustration"
        "illustration"
        "illustration"
        "buttons"
        "scroll";
      grid-template-columns: 1fr;
      grid-template-rows: 2rem repeat(6, 1fr) 50px;
      gap: 0;
      height: 600px;

      .banner-text {
        h1 {
          font-size: min(calc(100vw / 20), #{$fifty});
          width: 60%;
        }
      }

      .banner-illustration {
        justify-content: center;
        img {
          max-width: 500px;
        }
      }

      .banner-desc {
        grid-area: unset;
        display: none;
      }

      .banner-buttons {
        justify-content: start;
        align-items: start;
        flex-direction: column;

        a {
          margin-bottom: 5px;
        }
      }

      .banner-scroll {
        svg:first-child {
          display: none;
        }

        svg:nth-child(2) {
          display: block;
        }
      }
    }

    .advantages {
      .advantage-item {
        width: 45%;
      }
    }

    .circle-1,
    .circle-2,
    .circle-3,
    .triangle-1,
    .triangle-2,
    .semicircle-2 {
      display: none;
    }

    .cube-1 {
      bottom: 6rem;
    }

    .dashed-circle-group {
      top: 50%;
    }
  }

  @media (max-width: $mobileWidth) {
    .main-banner-grid {
      grid-template-areas:
        "text"
        "illustration"
        "illustration"
        "illustration"
        "buttons"
        "scroll";
      grid-template-rows: repeat(5, 1fr) 50px;
      height: 550px;

      .banner-text {
        h1 {
          font-size: min(calc(100vw / 17), #{$fifty});
          width: 70%;
        }
      }

      .banner-illustration {
        img {
          width: 100%;
          max-width: 460px;
        }
      }
    }

    .advantages {
      .advantage-item {
        justify-content: flex-start;
        width: 100%;
        margin-bottom: 2rem;
        grid-template-areas: "icon title" "icon desc";
        gap: 5px 1.5rem;
        text-align: left;

        .icon-rounded-wrapper {
          grid-area: icon;
          width: 53px;
          height: 53px;
          padding: 0.8rem;
        }

        h3 {
          grid-area: title;
          margin: 0;
        }

        p {
          grid-area: desc;
          margin: 0;
          font-size: $twelve;
        }
      }
    }
    .cube-1 {
      right: -1.5rem;
      bottom: 13rem;
    }
  }
}
</style>
