<template>
  <section :id="id" class="features-section">
    <ImageDecoration type="triangle" className="triangle-1" :width="21">
    </ImageDecoration>
    <ImageDecoration type="triangle" className="triangle-2" :width="21">
    </ImageDecoration>
    <ImageDecoration type="cube" className="cube-1" :width="40">
    </ImageDecoration>
    <ImageDecoration
      type="dashed-semicircle"
      className="semicircle-1"
      :width="172"
    >
    </ImageDecoration>
    <div class="tree-decoration">
      <inline-svg
        :src="require('@/assets/imgs/tree-2.svg')"
        width="115px"
      ></inline-svg>
    </div>
    <PageWrapper>
      <div class="features-title">
        <span>{{ $t("aboutULeague") }}</span>
        <h3>{{ $tc("feature", 2) }}</h3>
      </div>
      <div class="features-grid">
        <div class="main-feature">
          <span><IndentLine />{{ $t("chooseOptions") }}</span>
          <h3>{{ $t("manageOrPlay") }}</h3>
          <p>{{ $t("manageOrPlayDesc") }}</p>
          <a :href="downloadAppLink" target="_blank">
            <inline-svg
              :src="require('@/assets/icons/next.svg')"
              width="60"
            ></inline-svg>
          </a>
        </div>
        <Card class="uleague-player">
          <div class="card-head">
            <inline-svg
              :src="require('@/assets/icons/bowl.svg')"
              width="50"
            ></inline-svg>
            <div class="counter">
              <span>+{{ features.uleaguePlayerNum }}</span>
              {{ $t("uleaguePlayer.uleaguePlayers") }}
            </div>
          </div>
          <h3>{{ $t("uleaguePlayer.title") }}</h3>
          <p>{{ $t("uleaguePlayer.desc") }}</p>
          <FeaturesList :featuresList="features.uleaguePlayer"></FeaturesList>
          <a :href="downloadAppLink" target="_blank" class="card-action">
            {{ $t("uleaguePlayer.action") }}
            <inline-svg
              :src="require('@/assets/icons/next-arrow.svg')"
              width="15"
            ></inline-svg>
          </a>
        </Card>
        <Card>
          <div class="card-head">
            <inline-svg
              :src="require('@/assets/icons/admin.svg')"
              width="50"
            ></inline-svg>
            <div class="counter">
              <span>+{{ features.uleagueAdminNum }}</span>
              {{ $t("uleagueAdmin.admins") }}
            </div>
          </div>
          <h3>{{ $t("uleagueAdmin.title") }}</h3>
          <p>{{ $t("uleagueAdmin.desc") }}</p>
          <FeaturesList :featuresList="features.uleagueAdmin"></FeaturesList>
          <a :href="downloadAppLink" target="_blank" class="card-action">
            {{ $t("uleagueAdmin.action") }}
            <inline-svg
              :src="require('@/assets/icons/next-arrow.svg')"
              width="15"
            ></inline-svg>
          </a>
        </Card>
      </div>
    </PageWrapper>
  </section>
</template>

<script>
import InlineSvg from 'vue-inline-svg'
import Card from '@/components/Card/Card'
import ImageDecoration from '@/components/decorations/ImageDecoration'
import IndentLine from '@/components/decorations/IndentLine'
import FeaturesList from '@/components/FeaturesList/FeaturesList'
import PageWrapper from '@/components/PageWrapper/PageWrapper'
import { FEATURES } from '@/shared/constants'
import { mapState } from 'vuex'

export default {
  props: {
    id: String,
  },
  data() {
    return {
      features: FEATURES,
    }
  },
  computed: {
    ...mapState(['downloadAppLink']),
  },
  components: {
    Card,
    FeaturesList,
    ImageDecoration,
    IndentLine,
    InlineSvg,
    PageWrapper,
  },
}
</script>

<style lang="scss">
@import "@/css/base.scss";

.features-section {
  position: relative;
  padding-bottom: 3rem;
  padding-top: 2rem;
  overflow: hidden;
  background-color: $white2Color;

  .semicircle-1 {
    bottom: -1.8rem;
    left: 50%;
    transform: scaleX(0.7);
  }

  .triangle-1 {
    right: 3%;
    top: 7%;
  }

  .triangle-2 {
    display: none;
  }

  .cube-1 {
    top: 10%;
    left: 2rem;
  }

  .tree-decoration {
    position: absolute;
    bottom: 0;
    right: 3%;
    display: flex;
  }

  .features-title {
    text-align: center;

    span {
      font-size: $twelve;
      color: $secondaryColor;
    }

    h3 {
      text-transform: uppercase;
      font-size: $eighteen;
      color: $primaryColor;
      letter-spacing: 1px;
      margin-top: 0.3em;
    }
  }

  .features-grid {
    display: flex;
    justify-content: space-between;
    position: relative;

    h3 {
      text-transform: uppercase;
      font-size: $eighteen;
      color: $primaryColor;
      letter-spacing: 1px;
      margin-bottom: 0;
    }

    .main-feature {
      width: 25%;
      margin-right: 2.5rem;

      span {
        font-size: $twelve;
        color: $grey1Color;
        text-transform: uppercase;
      }

      p {
        color: $grey1Color;
        font-size: $twentyfour;
      }
    }

    .card {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 35%;
      padding: 1.8rem;
      background-color: $whiteColor;
      margin-right: 2.5rem;
      color: $primaryColor;
      font-size: $fourteen;

      .card-head {
        display: flex;
        justify-content: space-between;
        width: 100%;

        .counter {
          align-self: center;
          position: relative;
          top: 5px;

          span {
            font-weight: bold;
          }
        }
      }

      .features-list {
        .feature-item {
          .icon-container {
            margin-right: 15px;
            padding-top: 1px;

            svg {
              width: 13px;
            }
          }
        }
      }

      .card-action {
        padding: 1rem 1rem 1rem 0;
        color: $secondaryColor;
        font-size: $fourteen;
        text-transform: uppercase;
        font-weight: 500;
        letter-spacing: 0.5px;
        cursor: pointer;
      }
    }
  }

  @media (max-width: $largeTabletWidth) {
    .cube-1 {
      top: 6%;
      left: 0.5rem;
    }

    .features-grid {
      flex-wrap: wrap;
      justify-content: space-around;

      .main-feature {
        width: 100%;
        margin-right: 0;
        margin-bottom: 2rem;
      }

      .card {
        width: 45%;
        margin-right: 0;
      }
    }
  }

  @media (max-width: $tabletWidth) {
    .cube-1,
    .semicircle-1 {
      display: none;
    }

    .triangle-2 {
      display: block;
      left: -7px;
      top: 25rem;
    }

    .tree-decoration {
      right: -3%;
    }

    .features-grid {
      .main-feature {
        svg {
          display: none;
        }
      }

      .card {
        width: 100%;
        max-width: 500px;

        &.uleague-player {
          margin-bottom: 1.5rem;
        }

        .card-action {
          font-size: $sixteen;

          svg {
            width: $sixteen;
          }
        }
      }
    }
  }

  @media (max-width: $mobileWidth) {
    .triangle-1 {
      right: 1%;
      top: 5rem;
    }

    .tree-decoration {
      right: -5%;
    }
  }
}
</style>
