<template>
  <span class="indent-line-container">
    <div class="indent-line">
      <div class="middle-line"></div>
    </div>
  </span>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
@import "@/css/base.scss";

.indent-line-container {
  padding-left: 29px;
  position: relative;
  margin-right: 8px;

  .indent-line {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;

    .middle-line {
      width: 100%;
      height: 1px;
      background-color: $grey4Color;
    }
  }
}
</style>
