<template>
  <div class="layout">
    <HeaderSection />
    <slot></slot>
    <FooterSection />
  </div>
</template>

<script>
import HeaderSection from '@/components/HeaderSection/HeaderSection'
import FooterSection from '@/components/FooterSection/FooterSection'

export default {
  components: {
    FooterSection,
    HeaderSection,
  },
}
</script>

<style lang="scss" scoped>
@import "@/css/base.scss";

.layout {
  min-height: 100vh;
  position: relative;
  padding-bottom: 80px;

  .app-header {
    position: sticky;
    top: 0;
    z-index: 999;
  }

  .footer-section {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  }

  @media (max-width: $tabletWidth) {
    padding-bottom: 250px;
  }
}
</style>
