import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home/Home.vue'
import NotFound from '../views/NotFound/NotFound.vue'
import { auth } from '../db/firebase'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (login.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "login" */ '../views/Login/Login.vue'),
  },
  {
    path: '/terms',
    name: 'Terms',
    component: () => import(/* webpackChunkName: "terms" */ '../views/Terms/Terms.vue'),
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: () => import(/* webpackChunkName: "privacy-policy" */ '../views/PrivacyPolicy/PrivacyPolicy.vue'),
  },
  {
    path: '/user/profile',
    name: 'UserProfile',
    component: () => import(/* webpackChunkName: "user-profile" */ '../views/Profile/Profile.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/delete-account',
    name: 'DeleteAccount',
    component: () => import(/* webpackChunkName: "delete-account" */ '../views/Account/DeleteAccount.vue'),
  },
  {
    path: '*',
    component: NotFound,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

// NAVIGATION GUARDS
router.beforeEach((to, from, next) => {
  if (to.path === '/login' && auth.currentUser) {
    return next('/user/profile')
  }

  // Validate authentication
  const requiresAuth = to.matched.some(r => r.meta.requiresAuth)
  if (requiresAuth && !auth.currentUser) {
    next('/login')
  } else {
    next()
  }
})

export default router
