<template>
  <header class="app-header">
    <nav class="app-nav">
      <router-link to="/">
        <inline-svg
          :src="require('@/assets/logos/logo-carbon.svg')"
          title="Logo"
          class="main-logo"
        ></inline-svg>
      </router-link>
      <div class="spacer"></div>
      <LangSelector />
    </nav>
  </header>
</template>

<script>
import InlineSvg from 'vue-inline-svg'
import LangSelector from '@/components/LangSelector/LangSelector'

export default {
  components: {
    InlineSvg,
    LangSelector,
  },
}
</script>

<style lang="scss">
@import "@/css/base.scss";

.app-header {
  .app-nav {
    align-items: center;
    padding-right: 2rem;
    .main-logo {
      width: 252px;
    }
  }

  @media (max-width: $largeTabletWidth) {
    .app-nav {
      .main-logo {
        width: 155px;
      }
    }
  }
}
</style>
