<template>
  <div :class="['decoration', className]">
    <inline-svg
      :src="require(`@/assets/icons/${type}.svg`)"
      title="Decoration"
      :width="width"
    ></inline-svg>
  </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg'

export default {
  props: {
    className: String,
    // circle, triangle, cube, dashed-semicircle
    type: {
      type: String,
      validator: function(value) {
        // The value must match one of these strings
        return (
          ['circle', 'triangle', 'cube', 'dashed-semicircle'].indexOf(value) !==
          -1
        )
      },
    },
    width: Number,
  },
  components: {
    InlineSvg,
  },
}
</script>

<style>
.decoration {
  position: absolute;
}
</style>
