<template>
  <div class="avatar"><img :src="user.photoUrl" alt="user image" /></div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState(['user']),
  },
}
</script>

<style lang="scss" scoped>
@import "@/css/base.scss";

.avatar {
  $avatarWidth: 100px;
  width: $avatarWidth;
  height: $avatarWidth;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 2px solid $primaryColor;
  overflow: hidden;
  background-color: $secondaryColor;

  img {
    width: 100%;
  }
}
</style>
