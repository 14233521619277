<template>
  <section :id="id" class="subscribe-section">
    <ImageDecoration type="circle" className="circle-1" :width="21" />
    <ImageDecoration type="triangle" className="triangle-1" :width="21" />
    <ImageDecoration type="triangle" className="triangle-2" :width="21" />
    <ImageDecoration
      type="dashed-semicircle"
      className="semicircle-1"
      :width="172"
    />
    <div class="tree-decoration">
      <inline-svg
        :src="require('@/assets/imgs/tree.svg')"
        title="Tree"
        width="94px"
      ></inline-svg>
    </div>
    <div class="messages" :class="{ visible: showMessage }">
      {{ message }}
    </div>
    <PageWrapper>
      <div class="subscribe-grid">
        <div class="subscribe-text">
          <p class="subtitle">
            <IndentLine />{{ $t("subscribeForm.subtitle") }}
          </p>
          <h3 class="title">{{ $t("subscribeForm.title") }}</h3>
        </div>
        <div class="subscribe-form">
          <form @submit.prevent="submitSubscribe">
            <div class="input-group">
              <div class="input-wrapper">
                <input
                  type="email"
                  :disabled="loading"
                  :placeholder="$t('subscribeForm.placeholder')"
                  v-model.trim="subscribeEmail"
                />
              </div>
              <button type="submit" class="btn-solid" :disabled="loading">
                {{ $t("subscribeForm.subscribe") }}
                <div class="loading-dual-ring" v-if="loading"></div>
              </button>
              <div class="write-support">
                {{ $t("subscribeForm.writeSupport") }}
              </div>
            </div>
          </form>
        </div>
      </div>
    </PageWrapper>
  </section>
</template>

<script>
import InlineSvg from 'vue-inline-svg'
import PageWrapper from '@/components/PageWrapper/PageWrapper'
import IndentLine from '@/components/decorations/IndentLine'
import ImageDecoration from '@/components/decorations/ImageDecoration'

import { subscribeNewsletter } from '@/db/newsletter'

export default {
  props: {
    id: String,
  },
  data() {
    return {
      subscribeEmail: '',
      loading: false,
      message: '',
      showMessage: false,
    }
  },
  watch: {
    message(newVal) {
      if (newVal) {
        this.showMessage = true
        const self = this
        setTimeout(() => {
          self.cleanMessage()
        }, 5000)
      }
    },
  },
  methods: {
    cleanMessage() {
      this.showMessage = false
      const self = this
      setTimeout(() => {
        self.message = ''
      }, 300)
    },
    async submitSubscribe() {
      if (this.loading) {
        return
      }

      if (!this.subscribeEmail) {
        return
      }

      this.loading = true
      try {
        await subscribeNewsletter(this.subscribeEmail.toLowerCase())
        this.message = this.$t('subscribeForm.subscribeOk')
        this.subscribeEmail = ''
      } catch (error) {
        console.error(error)
        this.message = this.$t('error.genericError')
      }
      this.loading = false
    },
  },
  components: {
    InlineSvg,
    IndentLine,
    ImageDecoration,
    PageWrapper,
  },
}
</script>

<style lang="scss" scoped>
@import "@/css/base.scss";

.subscribe-section {
  background-color: $primaryColor;
  padding: 5rem 0;
  position: relative;
  overflow: hidden;

  .circle-1 {
    left: 15px;
    top: 20px;
  }

  .triangle-1 {
    right: 26%;
    top: 20%;
  }

  .triangle-2 {
    left: 15%;
    bottom: 10%;
  }

  .semicircle-1 {
    right: 0;
    bottom: -10px;
    transform: rotate(-15deg);
  }

  .tree-decoration {
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
  }

  .messages {
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    background-color: $secondaryColor;
    border-radius: 5px;
    max-width: 300px;
    padding: 1rem 1.5rem;
    opacity: 0;
    transition: opacity 200ms ease-in;
    z-index: 1;

    &.visible {
      opacity: 1;
    }
  }

  .subscribe-grid {
    display: grid;
    grid-template-areas: "desc appform";
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    position: relative;

    .subscribe-text {
      text-transform: uppercase;
      color: $whiteColor;
      letter-spacing: 1px;
      width: 80%;

      .subtitle {
        margin-top: 1rem;
        font-size: $twelve;
      }

      .title {
        font-size: $twentysix;
      }
    }

    .subscribe-form {
      display: flex;
      justify-content: center;
      align-items: center;

      form {
        display: flex;
        width: 70%;
      }

      .input-group {
        $inputHeight: 43px;

        width: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 7px;
        border: 1px solid $grey1Color;

        &:focus-within {
          border-color: $whiteColor;
        }

        .input-wrapper {
          display: inline-flex;
          height: $inputHeight;
          flex-grow: 1;

          input {
            height: 100%;
            width: 100%;
            padding-left: 18px;
            padding-right: 4px;
            border: none;
            background: transparent;
            font-size: $sixteen;
            color: $whiteColor;

            &:focus {
              outline: none;
            }
          }
        }

        .btn-solid {
          height: $inputHeight;
          color: $primaryColor;
          background-color: $whiteColor;
          padding: 0.8rem 1.2rem;
          flex-grow: 0;
          border: none;
          outline: none;
          position: relative;
        }

        .write-support {
          position: absolute;
          top: 100%;
          left: 50%;
          transform: translateX(-50%);
          margin-top: 1rem;
          white-space: nowrap;
          color: $whiteColor;
          font-size: $twelve;
        }
      }

      .loading-dual-ring {
        position: absolute;
        left: 100%;
        top: 0;
        display: inline-block;
        width: 40px;
        height: 40px;
      }
      .loading-dual-ring:after {
        content: " ";
        display: block;
        width: 24px;
        height: 24px;
        margin: 4px;
        border-radius: 50%;
        border: 5px solid $whiteColor;
        border-color: $whiteColor transparent $whiteColor transparent;
        animation: lds-dual-ring 1.2s linear infinite;
      }
      @keyframes lds-dual-ring {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }
  }

  @media (max-width: $largeTabletWidth) {
    .subscribe-grid {
      grid-template-areas: "desc" "appform";
      grid-template-columns: 1fr;

      .subscribe-text {
        width: 100%;
      }
    }

    .triangle-1 {
      right: 15%;
    }
  }

  @media (max-width: $tabletWidth) {
    padding-top: 2rem;

    .subscribe-grid {
      .subscribe-text {
        .title {
          font-size: $eighteen;
        }
      }

      .subscribe-form {
        .input-group {
          flex-direction: column;
          border: none;

          .input-wrapper {
            width: 100%;
            margin-bottom: 1.3rem;
            border-radius: 5px;
            border: 1px solid $grey1Color;

            &:focus-within {
              border-color: $whiteColor;
            }
          }
        }
      }
    }

    .circle-1,
    .triangle-1,
    .triangle-2,
    .tree-decoration {
      display: none;
    }
  }

  @media (max-width: $mobileWidth) {
    .subscribe-grid {
      .subscribe-form {
        form {
          width: 100%;
        }
      }
    }
  }
}
</style>
