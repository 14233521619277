<template>
  <section :id="id" class="promotional-video-section">
      <PageWrapper>
        <div ref="videoContainer"></div>
      </PageWrapper>
  </section>
</template>

<script>
import PageWrapper from '@/components/PageWrapper/PageWrapper'
import { supportedLangs } from '@/i18n'

export default {
  props: {
    id: String,
  },
  components: {
    PageWrapper,
  },
  mounted() {
    this.renderVideo()
  },
  watch: {
    '$i18n.locale': {
      immediate: true,
      handler() {
        this.renderVideo()
      },
    },
  },
  methods: {
    renderVideo() {
      const videoContainer = this.$refs.videoContainer
      if (!videoContainer) {
        return
      }

      videoContainer.innerHTML = ''

      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = 'https://play.vidyard.com/embed/v4.js'
      script.async = true
      document.head.appendChild(script)

      const videoIds = {
        [supportedLangs.en]: {
          src: 'https://play.vidyard.com/4J8pEdZtkaLN3bBxqFBbNZ.jpg',
          uuid: '4J8pEdZtkaLN3bBxqFBbNZ',
        },
        [supportedLangs.es]: {
          src: 'https://play.vidyard.com/cXq7eQZPqgtqXwcXHt3gzh.jpg',
          uuid: 'cXq7eQZPqgtqXwcXHt3gzh',
        },
      }

      const locale = this.$i18n.locale
      const { src, uuid } = videoIds[locale] || videoIds.en

      const image = document.createElement('img')
      image.style.width = '100%'
      image.style.margin = 'auto'
      image.style.display = 'block'
      image.className = 'vidyard-player-embed'
      image.src = src
      image.setAttribute('data-uuid', uuid)
      image.setAttribute('data-v', '4')
      image.setAttribute('data-type', 'inline')

      videoContainer.appendChild(image)
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/css/base.scss";

.promotional-video-section {
  background-color: $secondaryColor;
  padding: 4rem 0 2rem;

  div:first-child {
    max-width: $tabletWidth;
  }
}
</style>
