<template>
  <section :id="id" class="tournaments-section">
    <ImageDecoration type="triangle" className="triangle-1" :width="21" />
    <ImageDecoration type="circle" className="circle-1" :width="18" />
    <ImageDecoration type="cube" className="cube-1" :width="40" />
    <ImageDecoration
      type="dashed-semicircle"
      className="semicircle-1"
      :width="172"
    />
    <div class="section-container">
      <div class="left-decoration">
        <div class="background">
          <img src="@/assets/imgs/big-circle.svg" alt="Big circle" width="626" />
        </div>
        <img
          class="app-screem"
          :src="require(`@/assets/imgs/app-screenshot-light-${$i18n.locale}.png`)"
          alt="App screenshot"
        />
      </div>
      <PageWrapper>
        <div class="tournaments-section-grid">
          <div class="tournaments-title">
            <span>{{ $t("knowCompetitions") }}</span>
            <h3>{{ $t("activeTournaments") }}</h3>
          </div>
          <carousel
            :navigationEnabled="!isMobileVersion"
            :perPage="carouselItems"
            :paginationPadding="6"
            navigationNextLabel=""
            navigationPrevLabel=""
            paginationColor="#B7B7B7"
            paginationActiveColor="#F7C341"
          >
            <slide v-for="tournament in displayTournaments" :key="tournament.id">
              <TournamentCard :tournament="tournament"></TournamentCard>
            </slide>
          </carousel>
          <div class="outdated">
            {{ $t("tournamentsOutdated") }}
          </div>
        </div>
      </PageWrapper>
    </div>
  </section>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel'
import ImageDecoration from '@/components/decorations/ImageDecoration'
import PageWrapper from '@/components/PageWrapper/PageWrapper'
import TournamentCard from '@/components/TournamentCard/TournamentCard'

import { getHomeTournaments } from '@/db/tournaments'
import baseTournaments from './base-tournaments.json'

export default {
  props: {
    id: String,
  },
  data() {
    return {
      loading: false,
      tournaments: [],
      windowSize: window.innerWidth,
    }
  },
  computed: {
    isMobileVersion() {
      return this.windowSize <= 768
    },
    carouselItems() {
      return this.isMobileVersion ? 1 : 2
    },
    displayTournaments() {
      return [...this.tournaments, ...baseTournaments]
    },
  },
  methods: {
    async loadTournaments() {
      if (this.loading) {
        return
      }

      this.loading = true
      try {
        const snap = await getHomeTournaments()
        if (!snap.empty) {
          this.tournaments = []
          const homeTournaments = []
          snap.forEach(doc => {
            homeTournaments.push({ ...doc.data(), id: doc.id })
          })
          this.tournaments = homeTournaments
        }
      } catch (error) {
        console.log(error)
      }
      this.loading = false
    },
    onWindowResize(e) {
      if (this.windowSize !== window.innerWidth) {
        this.windowSize = window.innerWidth
      }
    },
  },
  created() {
    window.addEventListener('resize', this.onWindowResize)
  },
  mounted() {
    this.loadTournaments()
  },
  destroyed() {
    window.removeEventListener('resize', this.onWindowResize)
  },
  components: {
    Carousel,
    ImageDecoration,
    PageWrapper,
    Slide,
    TournamentCard,
  },
}
</script>

<style lang="scss" scoped>
@import "@/css/base.scss";

.tournaments-section {
  position: relative;
  background-color: $white2Color;
  height: 670px;
  overflow: hidden;

  .section-container {
    display: flex;
    width: 100%;
    height: 100%;

    .left-decoration {
      width: 30%;
      max-width: 440px;
      height: 100%;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      .background {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        padding: 22px 30px 22px 0;
      }

      .app-screem {
        position: inherit;
        width: 60%;
      }
    }

    .page-wrapper {
      width: 850px;
      margin-left: 5rem;
      margin-right: 1rem;
    }

    .tournaments-section-grid {
      position: relative;

      .tournaments-title {
        text-align: center;
        padding-top: 3rem;

        span {
          font-size: $twelve;
          color: $secondaryColor;
        }

        h3 {
          color: $primaryColor;
          text-transform: uppercase;
          font-size: $eighteen;
          margin-top: 0.3rem;
          letter-spacing: 1px;
        }
      }

      .torunament-card {
        margin: 1rem auto;
      }

      .outdated {
        font-size: $ten;
        color: $secondaryColor;
        text-align: right;
        margin-top: 2rem;
        margin-right: 2rem;
      }

      .VueCarousel {
        ::v-deep .VueCarousel-dot {
          width: 16px !important;
          height: 6px !important;
          border-radius: 20px;
          transition: all 50ms ease-in;
          outline: none;

          &.VueCarousel-dot--active {
            width: 23px !important;
            height: 9px !important;
          }
        }

        ::v-deep .VueCarousel-navigation {
          .VueCarousel-navigation-prev::before {
            transform: rotate(180deg);
          }
          .VueCarousel-navigation-prev,
          .VueCarousel-navigation-next {
            width: 60px;
            height: 60px;
            outline: none;

            &::before {
              content: "";
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              background-image: url("../../assets/icons/next.svg");
            }

            &.VueCarousel-navigation--disabled::before {
              display: none;
            }
          }
          .VueCarousel-navigation-prev {
            transform: translateY(-80%) translateX(-15px);
          }
          .VueCarousel-navigation-next {
            transform: translateY(-80%) translateX(15px);
          }
        }
      }
    }
  }

  .circle-1 {
    top: 7%;
    right: 20%;
  }

  .triangle-1 {
    top: 7%;
    left: 40%;
  }

  .cube-1 {
    bottom: 5%;
    left: 37%;
  }

  .semicircle-1 {
    transform: rotate(-93deg) translateX(40%) translateY(195%);
    right: 0;
    bottom: 0;
  }

  @media (max-width: $desktopWidth) {
    .section-container {
      .left-decoration {
        .background {
          padding-right: 10px;
        }

        .app-screem {
          width: 70%;
        }
      }

      .page-wrapper {
        margin-left: 2%;
      }
    }
  }

  @media (max-width: $largeTabletWidth) {
    .section-container {
      .left-decoration {
        display: none;
      }

      .page-wrapper {
        margin: auto;
        min-width: 750px;
        width: 90%;
      }
    }
  }

  @media (max-width: $tabletWidth) {
    .triangle-1 {
      left: 20%;
    }

    .section-container {
      .page-wrapper {
        min-width: unset;
      }

      .tournaments-section-grid {
        .torunament-card {
          margin-left: 0;
        }

        .outdated {
          text-align: left;
        }

        .VueCarousel {
          ::v-deep .VueCarousel-wrapper {
            width: 90%;
            overflow: visible;
          }
        }
      }
    }
  }

  @media (max-width: $mobileWidth) {
    height: 460px;

    .triangle-1 {
      display: none;
    }
    .circle-1 {
      display: none;
    }
    .cube-1 {
      display: none;
    }
    .semicircle-1 {
      bottom: unset;
      top: 28%;
    }

    .section-container {
      .page-wrapper {
        width: 95%;
      }

      .tournaments-section-grid {
        .tournaments-title {
          padding: 0;
        }
      }
    }
  }
}
</style>
