<template>
  <header>
    <nav>
      <div
        class="menu-button"
        v-if="isMobileVersion"
        @click="openMenu = !openMenu"
      >
        <inline-svg
          :src="require('@/assets/icons/close.svg')"
          title="Close menu"
          width="20px"
          v-if="openMenu"
        ></inline-svg>
        <inline-svg
          :src="require('@/assets/icons/hamburger.svg')"
          title="Open menu"
          width="24px"
          v-else
        ></inline-svg>
      </div>
      <router-link to="/" @click.native="scrollToTop">
        <inline-svg
          :src="require('@/assets/logos/logo-carbon.svg')"
          title="Logo"
          class="main-logo"
        ></inline-svg>
      </router-link>
      <div class="nav-actions">
        <div class="left-side" v-if="!isMobileVersion">
          <router-link to="#features" @click.native="scroll">{{
            $tc("feature", 2)
          }}</router-link>
          <router-link to="#tournaments" @click.native="scroll">{{
            $tc("tournament", 2)
          }}</router-link>
          <router-link to="#contact" @click.native="scroll">{{
            $t("contact")
          }}</router-link>
        </div>
        <div class="right-side">
          <router-link to="/user/profile" v-if="isEnabledAuth && isLoggedUser">
            <Avatar />
          </router-link>
          <template v-else>
            <router-link to="/login" v-if="isEnabledAuth && !isMobileVersion">{{
              $t("login")
            }}</router-link>
            <LangSelector />
            <a :href="downloadAppLink" target="_blank" class="btn-solid" @click="trackStartPlayingEvent">{{
              $t("start")
            }}</a>
          </template>
        </div>
      </div>
      <div id="mobile-menu" v-if="isMobileVersion" :class="{ open: openMenu }">
        <router-link to="#features" @click.native="scroll">
          {{ $tc("feature", 2) }}
        </router-link>
        <router-link to="#tournaments" @click.native="scroll">
          {{ $tc("tournament", 2) }}
        </router-link>
        <router-link to="#contact" @click.native="scroll">
          {{ $t("contact") }}
        </router-link>
        <router-link to="/login" v-if="isEnabledAuth && !isLoggedUser">{{
          $t("login")
        }}</router-link>
        <div class="social-media">
          <a :href="igSocialMediaUrl" target="_blank">
            <inline-svg
              :src="require('@/assets/icons/ig.svg')"
              title="ULeague on Instagram"
              width="32px"
            ></inline-svg>
          </a>
          <a :href="fbSocialMediaUrl" target="_blank">
            <inline-svg
              :src="require('@/assets/icons/fb.svg')"
              title="ULeague on Facebook"
              width="32px"
            ></inline-svg>
          </a>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import InlineSvg from 'vue-inline-svg'
import Avatar from '@/components/Avatar/Avatar'
import LangSelector from '@/components/LangSelector/LangSelector'
import { SOCIAL_MEDIA } from '@/shared/constants'

export default {
  props: {
    scrollTo: Function,
  },
  created() {
    window.addEventListener('resize', this.onWindowResize)
  },
  components: {
    Avatar,
    InlineSvg,
    LangSelector,
  },
  data() {
    return {
      openMenu: false,
      windowSize: window.innerWidth,
    }
  },
  computed: {
    ...mapState(['user', 'downloadAppLink']),
    ...mapGetters(['isEnabledAuth']),
    igSocialMediaUrl() {
      return SOCIAL_MEDIA.instagram + 'yall'
    },
    fbSocialMediaUrl() {
      return SOCIAL_MEDIA.facebook
    },
    isMobileVersion() {
      return this.windowSize <= 768
    },
    isLoggedUser() {
      return this.user !== null
    },
  },
  destroyed() {
    window.removeEventListener('resize', this.onWindowResize)
  },
  methods: {
    onWindowResize(e) {
      if (this.windowSize !== window.innerWidth) {
        this.windowSize = window.innerWidth
      }
    },
    closeMenu() {
      this.openMenu = false
    },
    scroll() {
      this.scrollTo(this.$route.hash)
      this.closeMenu()
    },
    scrollToTop() {
      window.scroll(0, 0)
      this.closeMenu()
    },
    trackStartPlayingEvent() {
      // eslint-disable-next-line no-undef
      fbq('trackCustom', 'StartPlaying', {})
      // If you want the link to work as usual after tracking, return true.
      // Otherwise, you can return false to prevent the default behavior of the link.
      return true
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/css/base.scss";

nav {
  position: relative;
  text-transform: uppercase;

  .main-logo {
    width: 252px;
  }

  .nav-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 1rem;
    width: 100%;

    .left-side,
    .right-side {
      display: flex;
    }

    .right-side {
      padding-right: 2rem;
      align-items: center;
    }

    a {
      padding: 15px 20px;
      margin-left: 10px;
      font-size: $twelve;
      white-space: nowrap;
    }

    .btn-solid {
      color: $whiteColor;
      background-color: $primaryColor;
      border-radius: 5px;
      font-weight: 900;

      &:visited {
        color: $whiteColor;
      }
    }

    .avatar {
      $avatarWidth: 50px;
      width: $avatarWidth;
      height: $avatarWidth;
    }
  }

  .menu-button {
    margin: 0.5rem 0.1rem;
    padding-left: 1rem;
    min-width: 2rem;
    width: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  a {
    text-decoration: none;
    color: $primaryColor;

    &:visited {
      color: $primaryColor;
    }
  }

  #mobile-menu {
    position: absolute;
    top: 100%;
    left: 0;
    width: 0;
    height: 0;
    background-color: $whiteColor;
    transition: width 0.2s linear;
    overflow: hidden;

    &.open {
      height: calc(100vh - 64px);
      width: 100%;
      overflow: auto;
    }

    a {
      display: block;
      border-bottom: 1px solid #dedede;
      padding: 1rem 3rem;
      text-align: left;
      font-size: $fourteen;
      color: #828282;
    }

    .social-media {
      display: flex;
      padding-left: 3rem;

      a {
        border: none;
        padding-left: 0;
      }
    }
  }

  @media (max-width: $largeTabletWidth) {
    .main-logo {
      width: 155px;
    }

    .nav-actions {
      a {
        padding: 10px 15px;
      }

      .avatar {
        $avatarWidth: 40px;
        width: $avatarWidth;
        height: $avatarWidth;
      }
    }
  }

  @media (max-width: $tabletWidth) {
    .nav-actions {
      justify-content: flex-end;
      .right-side {
        padding-right: 0.5rem;

        .btn-solid {
          padding: 8px 10px;
        }
      }
    }
  }
}
</style>
