import Vue from 'vue'
import VueMeta from 'vue-meta'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import { auth } from './db/firebase'

Vue.config.productionTip = false

Vue.use(VueMeta)

let app
auth.onAuthStateChanged((loggedUser) => {
  store.dispatch('loadFeatures')

  if (!app) {
    new Vue({
      router,
      store,
      i18n,
      render: h => h(App),
    }).$mount('#app')
  }

  if (loggedUser) {
    store.dispatch('loadUser', loggedUser)
  }
})
