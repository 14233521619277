<template>
  <div class="card">
    <slot></slot>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>
.card {
  box-shadow: 0 2px 20px rgba(173, 173, 173, 0.18);
}
</style>
